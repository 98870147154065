const app = 'crystal-companion'

// Add location for auto config to store the extracted settings in webpack plugin
let config = (window && window.__runtime_config)

if (!config) {
  /* auto-config: defaults */
  const defaults = {
    theme: {

    },
    images: {
      logo: 'logo.svg'
    },
    modules: {
      auth: {
        firebase: {
          apiKey: 'AIzaSyDuC35YVpJdtPpSuJhXyPGLy0qW8xB_7Oo',
          authDomain: `${app}.firebaseapp.com`,
          messagingSenderId: '643709085031'
        }
      }
    }
  }

  config = defaults

  // Can be overruled from dev-tools for easier testing
  if (window && window.localStorage) {
    const saved = JSON.parse(window.localStorage.getItem(`${app}-cfg`))
    if (saved) {
      config = { 
        ...defaults,
        ...saved
      }
    }
  }

  window.__runtime_config = config
}
export default config