import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
//import BaseRouter from '@my/ui/module-router/components/base-router-container'

//import Wrapper from '@my/ui/app-wrapper'

//import * as GlobalStore from '@my/ui/store'

import auth from 'modules/auth'
// import formtest from 'modules/formtest'
// import videochat from 'modules/videochat'
// import webworker from 'modules/webworker'
// import faster from 'modules/faster'
  
//const routes = [
  //...auth.routes,
  // ...formtest.routes,
  // ...videochat.routes,
  // ...webworker.routes,
  // ...faster.routes,
//]

// for( const key in modules ) {
//   const m = modules[key]
//   if (m.main) {
//     console.log(`Add route: /${key}`)
//     routes.push({path:`/${key}`, isPublic: true, component: m.main })
//   } else {
//     console.log(`Failed: ${key}:${JSON.stringify(m)}`)
//   }
// }

//Global store should have some local storage minimal config settings for theme color, last opened page, etc.
// Get this before the app is created to prevent blinking.
// let store = GlobalStore.create(
//   config,
//   [
//   ]
// )

//const theme = createMuiTheme(muitheme)

// export default () => {
//   return (
//     <Provider store={store}>
//       <MuiThemeProvider theme={theme}>
//         <CssBaseline />
//           <BaseRouter
//           store={store}
//           routes={routes}
//           appWrapper={Wrapper}
//           defaultRoute='/'
//         />
//       </MuiThemeProvider>
//     </Provider>
//   )
// }


class App extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    return <auth/>
  }
}

export default auth;