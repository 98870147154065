import React from 'react'
import { render } from 'react-dom'
import Root from './app'

render(<Root />, document.getElementById('root'))

if (module.hot) {
  module.hot.accept('app', () => {
    window.reload()
    const NewRoot = require('./app').default
    render(<NewRoot />, document.getElementById('root'))
  })
}
